<template>
	<div class="main columns">
		<SideBar class="column is-2"></SideBar>
		<div class="right-content column is-offset-2 is-10">
			<RouterView />
		</div>
	</div>
</template>

<script>
import SideBar from './components/SideBar.vue';

export default {
	name: 'App',
	components: { SideBar },
};
</script>

<style>
.montserrat-subrayada-regular {
	font-family: 'Montserrat Subrayada', sans-serif;
	font-weight: 400;
	font-style: normal;
}

.montserrat-subrayada-bold {
	font-family: 'Montserrat Subrayada', sans-serif;
	font-weight: 700;
	font-style: normal;
}
.poppins-thin {
	font-family: 'Poppins', sans-serif;
	font-weight: 100;
	font-style: normal;
}

.poppins-extralight {
	font-family: 'Poppins', sans-serif;
	font-weight: 200;
	font-style: normal;
}

.poppins-light {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-style: normal;
}

.poppins-regular {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-medium {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-semibold {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-style: normal;
}

.poppins-bold {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-style: normal;
}

.poppins-extrabold {
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	font-style: normal;
}

.poppins-black {
	font-family: 'Poppins', sans-serif;
	font-weight: 900;
	font-style: normal;
}

.poppins-thin-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 100;
	font-style: italic;
}

.poppins-extralight-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 200;
	font-style: italic;
}

.poppins-light-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-style: italic;
}

.poppins-regular-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-style: italic;
}

.poppins-medium-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-style: italic;
}

.poppins-semibold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-style: italic;
}

.poppins-bold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-style: italic;
}

.poppins-extrabold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	font-style: italic;
}

.poppins-black-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 900;
	font-style: italic;
}

#app {
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: white;
	font-weight: 400;
}

html,
body {
	padding: 0;
	margin: 0;
	background-color: #131417;
	min-height: 100vh;
}
#slowpiou .link-no-style {
	text-decoration: none;
	color: inherit;
}
.right-content {
	min-height: 100vh;
	background-color: #131417;
}

.titleconta {
	max-width: 850px;
	line-height: 1.4;
}
.addpa {
	max-width: 850px;
	color: #c7c9d2;
}
.pagination .pagination-container {
	column-gap: 10px;
	align-items: center;
}
.pagination .paginate-buttons {
	height: 35px;
	width: 35px;
	cursor: pointer;
	border-radius: 4px;
	background-color: transparent;
	border: 1px solid #70cb7c;
	color: #70cb7c;
}

.pagination .back-button,
.pagination .next-button {
	color: #70cb7c;
	border-radius: 8px;
	height: 45px;
	width: 45px;
	background-color: #131417;
}
.pagination .active-page {
	background-color: #70cb7c;
	color: #131417;
}
.pagination .paginate-buttons:hover {
	color: #131417;
	background-color: #70cb7c;
}
.pagination .active-page:hover {
	background-color: #70cb7c;
}

.pagination .back-button svg {
	transform: rotate(180deg) translateY(-2px);
}
.pagination .next-button svg {
	transform: translateY(2px);
}

.pagination .back-button:hover,
.pagination .next-button:hover {
	color: #131417;
	background-color: #70cb7c;
}

.pagination .back-button:active,
.pagination .next-button:active {
	color: #131417;
	background-color: #70cb7c;
}
</style>
