<template>
	<div class="sidebar is-flex is-flex-direction-column is-justify-content-space-between p-5">
		<div>
			<div class="is-flex is-align-items-center">
				<div class="menu-button">
					<button :click="openMenu">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
							<path
								fill-rule="evenodd"
								d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
							/>
						</svg>
					</button>
				</div>
				<div class="logo-containter m-auto">
					<p class="title is-size-5 has-text-white montserrat-subrayada-regular">SuperCoby</p>
				</div>
			</div>

			<div id="menucont" class="menucont pt-6 poppins-medium">
				<div class="close-button">
					<button :click="closeMenu">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
							<path
								d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
							/>
						</svg>
					</button>
				</div>
				<div class="menu-item pl-5 pt-3">
					<RouterLink to="/">Homepage</RouterLink>
				</div>
				<div class="menu-item pl-5 pt-3">
					<RouterLink to="/unlimiter/20">Unlimiter</RouterLink>
				</div>
				<div class="menu-item pl-5 pt-3">
					<RouterLink to="/tools">Tools</RouterLink>
				</div>
				<div class="menu-item pl-5 pt-3" id="models-menu-item">
					<span> 3D Models </span>
					<div class="sub-menu my-2">
						<div class="sub-menu-container" :class="keepSubMenuOpen ? 'active' : ''">
							<div class="sub-menu-item my-1 px-4">
								<RouterLink to="/stadium">Stadium</RouterLink>
							</div>
							<div class="sub-menu-item my-1 px-4">
								<RouterLink to="/united">United</RouterLink>
							</div>
							<div class="sub-menu-item my-1 px-4">
								<RouterLink to="/others">Others</RouterLink>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="socials">
			<p class="buttons is-justify-content-center">
				<a href="https://discord.gg/QhfB3UKSe5" class="button socials-button" target="_blank">
					<span class="icon is-small">
						<i class="fab fa-discord"></i>
					</span>
				</a>
				<a href="https://x.com/cobrasktm" class="button socials-button" target="_blank">
					<span class="icon is-small">
						<i class="fab fa-x-twitter"></i>
					</span>
				</a>
				<a href="https://www.youtube.com/@cobytm" class="button socials-button" target="_blank">
					<span class="icon is-small">
						<i class="fab fa-youtube"></i>
					</span>
				</a>
				<a href="https://github.com/SuperCoby/" class="button socials-button" target="_blank">
					<span class="icon is-small">
						<i class="fab fa-github"></i>
					</span>
				</a>
			</p>
			<div id="slowpiou" class="has-text-centered is-size-7 is-italic has-text-grey-dark">
				Made by<a target="_blank" href="https://github.com/slowpiou" class="link-no-style">&nbsp;slowpiou&nbsp;</a>with VueJS
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SideBar',
	components: {},
	computed: {
		keepSubMenuOpen() {
			return ['stadium', 'united', 'others'].includes(this.$route.params.page);
		},
	},
	methods: {
		openMenu() {},
		closeMenu() {},
	},
};
</script>

<style scoped>
a {
	color: white;
}
a:hover {
	color: #70cb7c;
}
.router-link-active {
	color: #70cb7c;
}
.sidebar {
	background-color: #1e1f25;
	min-height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
}
.logo-containter img {
	max-width: 150px;
}

.menu-button,
.close-button {
	display: none;
}

.menu-button button,
.close-button button {
	border: 0;
	background-color: unset;
	color: white;
}

.close-button {
	text-align: right;
	padding: 0 20px;
}

.close-button button svg {
	width: 25px;
	height: 25px;
}

.menu-button button svg {
	width: 30px;
	height: 30px;
}

.sub-menu {
	overflow: hidden;
}
.sub-menu-container {
	border-left: 2px solid white;
	overflow: hidden;
}
.sub-menu-container:not(.active) {
	transform: translateY(calc(-100%));
	transition: transform 0.2s ease-in-out;
}
.sub-menu-container:not(.active) .sub-menu-item {
	transform: translateX(calc(-100%));
	transition: transform 0.4s ease-in-out;
}
#models-menu-item {
	cursor: default;
}
#models-menu-item:hover .sub-menu .sub-menu-container {
	transform: translateY(0);
}
#models-menu-item:hover .sub-menu .sub-menu-container .sub-menu-item {
	transform: translateX(0);
}

.socials-button {
	color: #70cb7c !important;
	border-color: #70cb7c !important;
	border-width: 1px;
	box-shadow: 0 2px 10px 0px #00000040;
	font-size: 1rem;
	background-color: #1e1f25 !important;
}

.socials-button:hover,
.socials-button.router-link-active,
.socials-button.active {
	color: #1e1f25 !important;
	background-color: #70cb7c !important;
}
</style>
