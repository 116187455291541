<template>
	<div class="unlimiter">
		<div class="mcontentwrap p-6">
			<div class="titleconta pb-5 is-size-3 poppins-medium">
				Unlimiter
				<span class="spgre">{{ $route.params.version !== '20' ? ($route.params.version !== '13' ? '1.2' : '1.3') : '2.0' }}</span>
			</div>

			<div class="addpa pb-5">
				Welcome to our brand new website dedicated to Block3D!Share your 3D creations with the website community by posting them here. Welcome
				to our brand new website
			</div>

			<div class="buttons">
				<RouterLink
					class="button is-info is-outlined unlimiter-button poppins-semibold"
					to="/unlimiter/20"
					v-if="$route.params.version !== '20'"
					>Switch to Unlimiter 2.0</RouterLink
				>
				<button class="button is-info is-outlined unlimiter-button poppins-semibold active" v-else>Unlimiter 2.0</button>
				<RouterLink
					class="button is-info is-outlined unlimiter-button poppins-semibold"
					to="/unlimiter/13"
					v-if="$route.params.version !== '13'"
					>Switch to Unlimiter 1.3</RouterLink
				>
				<button class="button is-info is-outlined unlimiter-button poppins-semibold active" v-else>Unlimiter 1.3</button>
				<RouterLink
					class="button is-info is-outlined unlimiter-button poppins-semibold"
					to="/unlimiter/12"
					v-if="$route.params.version !== '12'"
					>Switch to Unlimiter 1.2</RouterLink
				>
				<button class="button is-info is-outlined unlimiter-button poppins-semibold active" v-else>Unlimiter 1.2</button>
			</div>

			<div class="is-size-4 poppins-medium pt-6 pb-5">Need help?</div>

			<div class="buttons">
				<a class="button is-info is-outlined unlimiter-button unlimiter-button-white poppins-semibold" :href="unlimiter.button1.link">{{
					unlimiter.button1.text
				}}</a>
				<a class="button is-info is-outlined unlimiter-button unlimiter-button-white poppins-semibold" :href="unlimiter.button2.link">{{
					unlimiter.button2.text
				}}</a>
				<a
					class="button is-info is-outlined unlimiter-button unlimiter-button-white poppins-semibold"
					:href="unlimiter.button3.link"
					v-if="$route.params.version !== '12'"
					>{{ unlimiter.button3.text }}</a
				>
			</div>

			<div class="tabssec">
				<div class="is-flex is-justify-content-space-between is-align-items-center mx-0 mt-3 mb-5">
					<div class="is-size-4 poppins-medium">Scripts</div>
					<div class="pagination" v-if="unlimiter.scripts.length">
						<vue-awesome-paginate
							:total-items="unlimiter.scripts.length"
							:items-per-page="perPage"
							:max-pages-shown="5"
							v-model="currentPage"
							:on-click="onClickHandler"
							:hide-prev-next="true"
						>
						</vue-awesome-paginate>
					</div>
				</div>
				<div class="columns is-multiline">
					<SetCard v-for="(i, j) in scriptsPaginated" :key="j" :item="i"></SetCard>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SetCard from '@/components/SetCard.vue';
import db from '@/db.js';

export default {
	name: 'UnlimiterView',
	components: { SetCard },
	data() {
		return {
			perPage: 8,
			currentPage: 1,
			unlimiter: {},
		};
	},
	computed: {
		scriptsPaginated() {
			return this.unlimiter.scripts.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
		},
	},
	watch: {
		$route(to) {
			this.unlimiter = db['unlimiter' + to.params.version];
			this.currentPage = 1;
		},
	},
	methods: {
		onClickHandler(page) {
			console.log(page);
		},
	},
	created() {
		this.unlimiter = db['unlimiter' + this.$route.params.version];
	},
};
</script>

<style>
.spgre {
	color: #70cb7c;
}
.unlimiter-button {
	color: #70cb7c !important;
	border-color: #70cb7c !important;
	border-width: 2px;
	box-shadow: 0 2px 10px 0px #00000040;
	font-size: 0.9rem;
	background-color: #131417 !important;
	width: 300px;
}

.unlimiter-button:hover,
.unlimiter-button.router-link-active,
.unlimiter-button.active {
	color: #131417 !important;
	background-color: #70cb7c !important;
}

.unlimiter-button-white {
	color: white !important;
	border-color: white !important;
}
.unlimiter-button-white:hover {
	background-color: white !important;
}
</style>
