<template>
	<div class="home">
		<div class="mcontentwrap p-6">
			<div class="titleconta pb-5 is-size-3 poppins-medium">Welcome to the best place to download and share Block3D. Join our community.</div>

			<div class="addpa pb-5">
				Welcome to our brand new website dedicated to Block3D!Share your 3D creations with the website community by posting them here.
			</div>

			<a class="button is-outlined is-info poppins-semibold mb-6" id="discord-button" href="https://discord.gg/uD4UAJr"> Join our Discord </a>

			<div class="tabssec">
				<div class="is-flex is-justify-content-space-between is-align-items-center mx-0 mt-3 mb-5">
					<div class="is-size-4 poppins-medium">Latest Blocks</div>
					<div class="pagenum">
						<RouterLink to="/stadium">More Blocks</RouterLink>
					</div>
				</div>
				<div class="columns is-multiline">
					<SetCard v-for="(i, j) in homePaginated" :key="j" :item="i"></SetCard>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SetCard from '@/components/SetCard.vue';
import db from '@/db.js';
export default {
	name: 'HomeView',
	components: { SetCard },
	data() {
		return {
			home: db.stadium,
		};
	},
	computed: {
		homePaginated() {
			return this.home.slice(0, 4);
		},
	},
};
</script>

<style scoped>
#discord-button {
	color: #7688d4;
	border-color: #7688d4;
	border-width: 2px;
	box-shadow: 0 2px 10px 0px #00000040;
	font-size: 0.9rem;
	width: 300px;
}
#discord-button:hover {
	color: #131417;
	background-color: #7688d4;
}
.pagenum a {
	color: #70cb7c;
	text-decoration: none;
}
</style>
