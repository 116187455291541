import { createWebHistory, createRouter } from 'vue-router';

import HomeView from './views/HomeView.vue';
import UnlimiterView from './views/UnlimiterView.vue';
import ModelsView from './views/ModelsView.vue';

const routes = [
	{ path: '/', component: HomeView },
	{ path: '/unlimiter/:version', component: UnlimiterView },
	{ path: '/:page', component: ModelsView },
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
