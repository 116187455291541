<template>
	<div class="column is-3 is-flex is-flex-direction-column">
		<div class="card">
			<span class="tag poppins-bold" :style="item.img ? '' : 'position: relative;'">
				{{ item.date }}
			</span>
			<div class="card-image" v-if="item.img">
				<figure class="image is-16by9">
					<img :src="item.img" alt="Placeholder image" />
				</figure>
			</div>
		</div>
		<div class="card-content pb-0">
			<div class="content">
				<p class="title">{{ item.title }}</p>
				<p class="subtitle mb-0" v-if="$route.params.page !== 'tools'">by {{ item.author }}</p>
				<p class="subtitle">{{ item.desc }}</p>
			</div>
		</div>
		<div class="card-footer p-4">
			<a class="button is-fullwidth poppins-bold" :href="item.downloadLink">Download {{ getDownloadButtonText }}</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SetCard',
	components: {},
	props: ['item'],
	data() {
		return {};
	},
	computed: {
		getDownloadButtonText() {
			if (this.$route.params.page === 'tools') {
				return 'tool';
			} else if (this.$route.params.version) {
				return 'script';
			} else {
				return 'pack';
			}
		},
	},
};
</script>

<style scoped>
.card {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.card,
.card-content,
.card-footer {
	background-color: #282a32;
}
.card-image .image img {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}
.card-footer {
	border: none;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}
.card-content {
	height: 100%;
}
.title {
	padding: 0 0 10px 0;
	font-weight: 500;
	font-style: normal;
	font-size: 1.1rem;
	color: white;
}
.subtitle {
	font-size: 0.8rem;
	line-height: 2;
	font-weight: 500;
	font-style: normal;
	color: #c7c9d2;
}
.button {
	font-size: 0.85rem;
	background-color: #70cb7c;
	color: #131417;
	font-style: normal;
	box-shadow: 0 2px 10px 0px #00000040;
	border-radius: 3px;
	border-color: #70cb7c;
}
.button:hover {
	background-color: #89ce92;
}
.tag {
	background-color: #70cb7c;
	color: #131417;
	padding: 2px 15px;
	border-radius: 20px;
	border-bottom-left-radius: 0;
	display: inline-block;
	font-size: 0.8rem;
	font-style: normal;
	box-shadow: 0 2px 10px 0px #00000040;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}
</style>
